import React from "react";
import { Grid, List } from "semantic-ui-react";
import style from "./style.module.css";
import colors from "../colors";
import Quote from "../quote";

const Paragraphs = ({ paragraphs }) => {
  if (!paragraphs || paragraphs.length === 0) {
    return null;
  }
  return paragraphs.map(p => <p key="p-key">{p}</p>);
};

const WhatChangedList = ({ list, siteSection }) => {
  if (!list || list.length === 0) {
    return null;
  }
  return (
    <List>
      {list.map(item => (
        <List.Item className={style.listItem} key={`list-${item}`}>
          <List.Icon
            name="check"
            size="big"
            style={{ color: colors[siteSection].main }}
          />
          <List.Content>
            <List.Header>{item}</List.Header>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

const CaseStudy = ({
  heading,
  intro,
  img,
  firstParagraph,
  paragraphs,
  quote,
  quoteName,
  whatChangedHeading,
  whatChangedText,
  whatChangedList,
  siteSection
}) => {
  return (
    <div className={style.caseStudyPanel}>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <h2 className={style.heading}>{heading}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            {intro ? <h3 className={style.intro}>{intro}</h3> : ""}
            {firstParagraph ? <h4>{firstParagraph}</h4> : ""}
            <Paragraphs paragraphs={paragraphs} />
            <Quote
              quote={quote}
              quoteName={quoteName}
              siteSection={siteSection}
            />
          </Grid.Column>
          <Grid.Column width={11}>
            {img ? (
              <img src={img} className={style.caseStudyImage} alt={heading} />
            ) : (
              ""
            )}
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {whatChangedHeading ? <h4>{whatChangedHeading}</h4> : ""}
                  {whatChangedText ? <p>{whatChangedText}</p> : ""}
                </Grid.Column>
                <Grid.Column>
                  <WhatChangedList
                    list={whatChangedList}
                    siteSection={siteSection}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default CaseStudy;
