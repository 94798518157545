import React from "react";
import { Tab } from "semantic-ui-react";
import style from "./style.module.css";

import "./style.css";

const CaseStudies = ({ panes, siteSection }) => {
  return (
    <Tab
      className={`${style.tabMenu} ${siteSection}`}
      menu={{ pointing: true }}
      panes={panes}
    />
  );
};

export default CaseStudies;
