import React, { useEffect, useState } from "react";
import { Container, Divider } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import HeadingOverImage from "../../components/heading-over-image/heading-over-image";
import FeatureList from "../../components/feature-list/feature-list";
import BodyText from "../../components/body-text/body-text";
import CaseStudy from "../../components/case-study/case-study";
import CaseStudies from "../../components/case-studies/case-studies";
import CTA from "../../components/cta/cta";
import Quote from "../../components/quote/quote";
import CookieUtils from '../../utils/cookie-utils';

const CaseStudy1 = () => (
  <CaseStudy
    siteSection="primary"
    heading="Lucas makes new friends"
    img="/images/primary/case_study_drums.jpg"
    intro="Lucas is autistic and has ADHD. He struggled to focus on tasks and make friends. At school he felt like an outsider, making him anxious and unable to join in activities."
    firstParagraph="One term changed his life"
    paragraphs={[
      "He joined Rocksteady in Year 3  as a drummer and soon began looking forward to lessons, making friends with bandmates and making everyone laugh with his jokes."
    ]}
    quote="Seeing him remain focused on stage in front of a hall full of people made me an emotional wreck!"
    quoteName="Nicola, Proud Parent"
    whatChangedHeading="What changed for Lucas"
    whatChangedText="He focuses on practicing the tricky drum parts again and again until he’s happy, improving his co-ordination and concentration skills."
    whatChangedList={[
      "Can’t wait to get to school on Rocksteady days",
      "Is calmer and more confident at school",
      "Has made new friends and feels like he belongs"
    ]}
  />
);

const CaseStudy2 = () => (
  <CaseStudy
    siteSection="primary"
    heading="George discovers his confidence"
    img="/images/primary/case_study_keys.jpg"
    intro="George has speech and language difficulties. This affects his self-confidence and makes it a struggle for him to speak to other children when working in groups."
    firstParagraph="Being in the band changed everything"
    paragraphs={[
      "He joined Rocksteady playing keyboards in Year 5 and instantly loved lessons and the inclusivity of being in a band. He has overcome every challenge his band leader’s thrown at him."
    ]}
    quote="I didn’t think he’d take part in the concert due to lack of confidence but he really enjoys the lessons and adores his tutor who is fantastic fun."
    quoteName="Proud Parent"
    whatChangedHeading="What changed for George"
    whatChangedText="He overcame his nerves before the big concert to play brilliantly, with support and reassuring glances from his Rocksteady band leader. He’s already looking forward to next year."
    whatChangedList={[
      "His confidence has soared",
      "Always the first to put his hand up in class",
      "Never wants classes to end because he loves playing"
    ]}
  />
);

const CaseStudy3 = () => (
  <CaseStudy
    siteSection="primary"
    heading="Tahj finds his calling"
    img="/images/primary/case_study_guitar.jpg"
    intro="Tahj is mature and confident with friends across the school in different year groups."
    firstParagraph="Playing ignited his passions"
    paragraphs={[
      "He has also been playing in a band for five years, his whole school life so far. During this time he has learned to play guitar, been an integral member of his band and played on stage fifteen times!"
    ]}
    quote="Being part of a band has given Tahj more drive, fuelled his interest in music and encouraged him to realise his potential. He’s made friends with different children across the school and knows how to work with others in a team. It’s also had a positive effect on his performance in the classroom."
    quoteName="Sarita, proud parent"
    whatChangedHeading="What changed for Tahj"
    whatChangedText="He went from dreaming about jamming with Queen and Green Day to playing on stage in his own band and giving it his all for the audience."
    whatChangedList={[
      "Has discovered a passion for music and playing guitar",
      "Benefits from improved concentration in other subjects",
      "His musicianship has grown along with his confidence"
    ]}
  />
);

const CaseStudy4 = () => (
  <CaseStudy
    siteSection="primary"
    heading="Sara goes from fan to performer"
    img="/images/primary/case_study_space_drum.jpg"
    intro="Sara has always been a quick learner and since joining a band has discovered another part of her personality. It turns out she loves being a musician and part of a team as well."
    firstParagraph="Nerves turn to excitement"
    paragraphs={[
      "She was a bit nervous at first as she wasn’t sure whether she’d be able to keep up with the more experienced children. All that changed though, as she quickly made friends with the rest of the band."
    ]}
    quote="From not being able to play at all, I’ve watched her become so confident that nothing phases her. Her performance at the school concerts is a highlight of our school calendar – it's amazing to see what her band have achieved each term."
    quoteName="Aditi, Proud Parent"
    whatChangedHeading="What changed for Sara"
    whatChangedText="She went from loving music to becoming a musician in a band, who all support each other in trying new things."
    whatChangedList={[
      "Learned to overcome nerves",
      "Dazzled audiences with her confidence",
      "Made new friends in a supportive environment"
    ]}
  />
);

const CaseStudy5 = () => (
  <CaseStudy
    siteSection="primary"
    heading="Ophelia finds her voice"
    img="/images/primary/case_study_drum.jpg"
    intro="Ophelia is music mad and has always been excited to hear new music on the radio and talk about her favourite bands."
    firstParagraph="Rocking out and playing loud"
    paragraphs={[
      "When she saw a Rocksteady concert at her school, she knew she wanted to get involved and started learning drums in the band Take This, who have a flair for performance with solos at the end of their songs and whole host of special moves."
    ]}
    quote="She used to struggle with standing in front of a large crowd, even standing up in assembly to collect a certificate. She would be very shy and almost dread standing in front of her peers. But when it comes to a Rocksteady show, Ophelia rushes over to her drum kit with a huge smile on her face and confidently performs in front of the whole school. She also notices beats in music that she didn’t before and starts tapping along with them. It’s such good fun for children, I think everyone should do it"
    quoteName="Dominique, Proud Parent"
    whatChangedHeading="What changed for Ophelia"
    whatChangedText="She found her expression in playing music and enjoys sharing her passion with everyone else via some very lively drum solos!"
    whatChangedList={[
      "Found an outlet through playing drums",
      "Contributes creatively to the direction of her band",
      "Enjoys playing with friends and performing live"
    ]}
  />
);

const CaseStudiesSection = () => (
  <CaseStudies
    siteSection="primary"
    panes={[
      {
        menuItem: "Lucas makes new friends",
        render: () => <CaseStudy1 />
      },
      {
        menuItem: "George discovers his confidence",
        render: () => <CaseStudy2 />
      },
      {
        menuItem: "Tahj finds his calling",
        render: () => <CaseStudy3 />
      },
      {
        menuItem: "Sara goes from fan to performer",
        render: () => <CaseStudy4 />
      },
      {
        menuItem: "Ophelia finds her voice",
        render: () => <CaseStudy5 />
      }
    ]}
  />
);

const Page = () => {
  const [urlParams, setUrlParams] = useState('');

  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  return (
    <LayoutInner>
      <SEO
          title="Information for Parents"
          keywords={[`rocksteady`]}
          description="Learn about Rocksteady’s in-school rock and pop music band lessons for your child. Lessons are fun, help boost confidence and support your child’s wellbeing."
      />

      <Container>
        <SecondaryNavBar
          product="primary"
          title="Information for Parents"
          titlePath="/info-for-parents/"
          titleActive={true}
          links={[]}
          cta="Sign Up Today"
          ctaLink="https://booking.rocksteadymusicschool.com"
        />

        <div className="videoWrapper wide">
          <iframe
            src={`https://player.vimeo.com/video/513312951${urlParams}`}
            width="640"
            height="480"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="Rocksteady Music School Primary"
          ></iframe>
        </div>

        <Divider hidden />

        <FeatureList
          siteSection="primary"
          features={[
            {
              title: "Learn to play in a band",
              text:
                "Learning an instrument should be fun. Your child can choose from the electric guitar, keyboard, drums or vocals and enjoy playing in a happy, supportive environment with their friends."
            },
            {
              title: "From first notes to favourite songs",
              text:
                "The Rocksteady Programme is for all children aged 4-11 and is proven to progress musicianship, confidence and teamwork skills with no prior experience necessary."
            },
            {
              title: "Lead by real musicians",
              text:
                "Your child’s music teacher should also be their role model. Which is why all Rocksteady Band Leaders are carefully chosen, extensively trained musicians who know how to inspire a life-long love of playing music. "
            },
            {
              title: "With regular live performances",
              text:
                "Playing on stage is a huge confidence booster. With termly concerts at school assemblies, your child will be able to show off their progress to friends, teachers and parents with the rest of their band."
            },
            {
              title: "In school time",
              text:
                "Lessons are 30 minutes long and take place in school time   with all instruments provided, making it hassle-free for parents."
            },
            {
              title: "And happily affordable",
              text:
                "Rocksteady is one of the most hassle-free ways to learn an instrument on a rolling subscription that you can cancel at any time. It’s risk free too – cancel in the first month and you’ll get a full refund."
            }
          ]}
        />

        <div className="videoWrapper wide">
          <iframe
            src={`https://player.vimeo.com/video/513312666${urlParams}`}
            width="640"
            height="480"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="Rocksteady Music School Primary"
          ></iframe>
        </div>

        <Divider hidden />

        <FeatureList
          siteSection="primary"
          features={[
            {
              title: "Confidence",
              text:
                "Lessons and concerts help children achieve together, boosting everyone’s confidence. Knowing they have the support of their bandmates gives children a safe place to try new things."
            },
            {
              title: "Wellbeing",
              text:
                "Playing an instrument is proven to reduce anxiety and promote emotional wellbeing, which improves overall happiness inside and outside school."
            },
            {
              title: "Resilience",
              text:
                "As a band, children work together to overcome challenges, problem solve and set their own goals. Creating positive learning behaviours to help them progress at school."
            },
            {
              title: "Attainment",
              text:
                "Learning to play an instrument improves children’s memory , numeracy and literacy, boosting their achievement at school and supporting a lifelong love of learning."
            },
            {
              title: "Behaviour",
              text:
                "Learning an instrument develops the part of the brain  that deals with behaviour regulation and motor skills, improving children’s focus and progress in class."
            },
            {
              title: "Inclusive",
              text:
                "Playing in a band is a great leveller. Everybody starts at the same point   regardless of ability, which can have a huge impact on children with special educational needs or those with English as an additional language."
            }
          ]}
        />
        <HeadingOverImage
          text="Changing Lives"
          img="/images/primary/bg_4.jpg"
          siteSection="primary"
        />

        <CaseStudiesSection />

        <HeadingOverImage
          text="Key Notes"
          img="/images/primary/bg_1.jpg"
          siteSection="primary"
        />

        <BodyText>
          <Quote
            siteSection="primary"
            quote="Rocksteady has been a fabulous experience for Radley. He has built his confidence singing in front of the school and we have loved every minute of it"
            quoteName="Proud Parent"
          />
        </BodyText>

        <FeatureList
          siteSection="primary"
          features={[
            {
              title: "A fun way to learn a musical instrument"
            },
            {
              title: "Support your child’s happiness and confidence"
            },
            {
              title:
                "They’ll make new friends and perform together in a live concert"
            },
            {
              title: "Lessons take place during the normal school day"
            },
            {
              title: "Hassle-free, with no need to buy instruments"
            },
            {
              title: "Professional equipment and expert tuition are included"
            },
            {
              title: "And happily affordable on a monthly rolling subscription"
            }
          ]}
        />

        <BodyText>
          <p>
            Lessons are booked on a first come first served basis. Follow the link
            below to secure your place now.
          </p>
          <CTA
            ctaLink="https://booking.rocksteadymusicschool.com"
            ctaText="Sign Up Today"
            siteSection="primary"
          />
        </BodyText>
      </Container>
    </LayoutInner>
  );
}

export default Page;
